import React from "react";

const Redirect = () => {
  React.useEffect(() => {
    window.location.replace("http://onelink.to/fixakorkort");
  }, []);
  return <></>;
};

export default Redirect;
