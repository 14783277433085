import React from "react";
import styled from "styled-components";
import ContactUsSection from "./../components/ContactUsSection";
import FeatureSection from "./../components/FeatureSection/FeatureSection";
import Footer from "./../components/Footer";
import IntroSection from "./../components/IntroSection";
import VideoSection from "./../components/VideoSection";

const Home = () => {
  return (
    <>
      <IntroSection />
      <VideoSection />
      <FeatureSection />
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Home;
