import React from "react";
import styled from "styled-components";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Redirect from "./screens/Redirect";
import PrivatePolicy from "./screens/PrivatePolicy";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

const App = () => {
  return (
    <Wrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<PrivatePolicy />} />
          <Route path="instagram" element={<Redirect />} />
          <Route path="app" element={<Redirect />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Wrapper>
  );
};

export default App;
